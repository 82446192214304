<template>
<div class="box">
    <div class="d-flex justify-content-center">
        <div class="title" :class="border+'-title'">
            <div class="mx-3 my-1"><slot name="title"></slot></div>
        </div>
    </div>
    <slot name="body"></slot>
</div>
</template>

<script>
    export default {
        name:'Box',
        props:{
            border:String,
        }
    }
</script>

<style lang="scss" scoped>
    .title h3{
        color: #fff;
        padding-left: 20px;
        padding-right: 20px;
    }
    .title{
        color: #fff;
        font-size: 23px;
        //padding-left: 20px;
        //padding-right: 20px;
        //background: transparent;
    }
</style>
