<template>
    <div class="customers">
        <box :border="border">
            <h3 slot="title">{{ $t("titles.coustomers")}}</h3>
            <div class="coustomers-main col-lg-8 col-10 mx-auto" slot="body">
                <div class="container" v-if="category == 'plast'">
                    <div class="row justify-content-center pt-4" v-if="!error">
                        <div class="col-lg-12 col-12 mt-3" v-for="item in items" :key="item.id">
                            <img class="customer-logo" :src="'/img/'+item.logo" :alt="item.name">
                        </div>
                    </div>
                    <div class="has-error" v-else>
                      <p>{{ error }}</p>
                    </div>
                </div>
                <div class="container" v-else>
                    <div class="row justify-content-center pt-2">
                        <div class="col-lg-3 col-md-3 col-6 p-2" v-for="item in items" :key="item.id">
                            <img class="w-100" :src="'/img/'+item.logo" :alt="item.name">
                        </div>
                    </div>
                </div>
            </div>
        </box>
    </div>
</template>

<script>
import Box from './Box.vue'
import httpClient from '@/services/http.service';
    export default {
    components: { Box },
        name:'Customers',
        props:{
            category:String,
            border:String,
        },
        data:()=>{
            return{
                items:null,
                error:null,
            }
        },
        methods: {
          async getData() {
            try {
              const {status , data} = await httpClient.get('/fa/coustomers',{params:{cid:this.category}});
                if (status == 200) {
                  this.items = data;
                }
            } catch (e) {
              this.error = 'Has Error In Send Data To Host';
            }
          }
        },
        async mounted() {
          await this.getData();

        }
    }
</script>

<style lang="scss" scoped>
    .customer-logo{
        width: 90%;
        height: 90%;
        margin:auto;
        display: flex;
    }
</style>
